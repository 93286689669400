import validate from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/node_modules/nuxt/dist/pages/runtime/validate.js";
import is_45authenticated_45global from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/middleware/isAuthenticated.global.js";
import is_45authorized_45global from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/middleware/isAuthorized.global.ts";
export const globalMiddleware = [
  validate,
  is_45authenticated_45global,
  is_45authorized_45global
]
export const namedMiddleware = {
  auth: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/middleware/auth.ts"),
  "can-access-route": () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/middleware/canAccessRoute.js"),
  "feature-enabled": () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/middleware/featureEnabled.ts"),
  guest: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/middleware/guest.ts"),
  unverified: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/middleware/unverified.ts"),
  verified: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/middleware/verified.ts")
}