import { default as activate1maWj4jzBPMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/auth/activate.vue?macro=true";
import { default as indexGIejMfCh3IMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/auth/index.vue?macro=true";
import { default as restoreXfmIBQc3CXMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/auth/organisations/restore.vue?macro=true";
import { default as register_45completeKM8SCRxIXPMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/auth/register-complete.vue?macro=true";
import { default as registerfZ778dyCrpMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/auth/register.vue?macro=true";
import { default as _91token_93SH6mdB9s8HMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/boards/[token].vue?macro=true";
import { default as indexAsfCYUl1F4Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/buzzer/index.vue?macro=true";
import { default as _91id_939GM5JGBxcDMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/dashboards/[id].vue?macro=true";
import { default as index4hzKfZ90vlMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/dashboards/index.vue?macro=true";
import { default as _91token_93wlA4lszU2iMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/dashboards/token/[token].vue?macro=true";
import { default as token1zHzxwuiOaMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/dashboards/token.vue?macro=true";
import { default as demomkyUXo5AUOMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/demo.vue?macro=true";
import { default as indexukJNmmzbU2Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/events/index.vue?macro=true";
import { default as impressumd3gnkHIKm1Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/impressum.vue?macro=true";
import { default as indexEFXtqqzcboMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/index.vue?macro=true";
import { default as _91id_936M2nLAJ7UcMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/leaderboards/[id].vue?macro=true";
import { default as indexuf4rJG7SNlMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/leaderboards/index.vue?macro=true";
import { default as _91id_93GlhBOWfi1WMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/organisation/deals/[id].vue?macro=true";
import { default as indexF8fFYHp47wMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/organisation/deals/index.vue?macro=true";
import { default as HSLtoRGBiYLbg4ksFPMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/organisation/devices/HSLtoRGB.js?macro=true";
import { default as RGBtoHSLkasdqhYxAgMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/organisation/devices/RGBtoHSL.js?macro=true";
import { default as _91id_93uhDr9y2LRNMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/organisation/devices/[id].vue?macro=true";
import { default as _91id_93DffktESwatMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/organisation/devices/devicegroup/[id].vue?macro=true";
import { default as indexiA5kDPDgfrMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/organisation/devices/index.vue?macro=true";
import { default as _91id_934PSruS8JbzMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/organisation/eventTypes/[id].vue?macro=true";
import { default as indexQjG0ELd2T4Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/organisation/eventTypes/index.vue?macro=true";
import { default as _91id_932jyN5ebLyQMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/organisation/goals/[id].vue?macro=true";
import { default as indexff6dHtWGTMMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/organisation/goals/index.vue?macro=true";
import { default as indexrQsCRZWNkaMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/organisation/settings/index.vue?macro=true";
import { default as indexzG2tsIYIwBMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/organisation/subscriptions/index.vue?macro=true";
import { default as _91id_93fDMG1ANLmzMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/organisation/users/[id].vue?macro=true";
import { default as indexJDACXwihqpMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/organisation/users/index.vue?macro=true";
import { default as _91id_93jnFOkkE5g6Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/sounds/[id].vue?macro=true";
import { default as entriesbQnGSywiCbMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/sounds/index/entries.vue?macro=true";
import { default as playlists1exrZCJAnHMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/sounds/index/playlists.vue?macro=true";
import { default as index1sqwfT3DrAMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/sounds/index.vue?macro=true";
import { default as indexl9jZC9zEFQMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/system/devices/index.vue?macro=true";
import { default as _91id_93U2QYTqiS1mMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/system/firmware/[id].vue?macro=true";
import { default as indexfRvCdp04RyMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/system/firmware/index.vue?macro=true";
import { default as _91id_93OhhFabqmvmMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/system/hardware/[id].vue?macro=true";
import { default as indextTg3AVBznZMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/system/hardware/index.vue?macro=true";
import { default as indexUSWYRsLn3HMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/system/logs/index.vue?macro=true";
import { default as _91id_936YPXOSOpI8Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/system/notificationTemplates/[id].vue?macro=true";
import { default as indexmhhgomSBPqMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/system/notificationTemplates/index.vue?macro=true";
import { default as _91id_93yr4owhcqpvMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/system/organisations/[id].vue?macro=true";
import { default as indexcZSsKmuD8TMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/system/organisations/index.vue?macro=true";
import { default as _91id_93Wxt1GMkVtcMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/system/plans/[id].vue?macro=true";
import { default as indexNyBnsVzypoMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/system/plans/index.vue?macro=true";
import { default as releaselogwmz60BZHHkMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/system/releaselog.vue?macro=true";
import { default as toolssqTwhD8gdoMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/system/tools.vue?macro=true";
import { default as _91id_93iJs50yRMUJMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/system/users/[id].vue?macro=true";
import { default as indexwKby0QEOYJMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/system/users/index.vue?macro=true";
export default [
  {
    name: activate1maWj4jzBPMeta?.name ?? "auth-activate",
    path: activate1maWj4jzBPMeta?.path ?? "/auth/activate",
    meta: activate1maWj4jzBPMeta || {},
    alias: activate1maWj4jzBPMeta?.alias || [],
    redirect: activate1maWj4jzBPMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/auth/activate.vue").then(m => m.default || m)
  },
  {
    name: indexGIejMfCh3IMeta?.name ?? "auth",
    path: indexGIejMfCh3IMeta?.path ?? "/auth",
    meta: indexGIejMfCh3IMeta || {},
    alias: indexGIejMfCh3IMeta?.alias || [],
    redirect: indexGIejMfCh3IMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: restoreXfmIBQc3CXMeta?.name ?? "auth-organisations-restore",
    path: restoreXfmIBQc3CXMeta?.path ?? "/auth/organisations/restore",
    meta: restoreXfmIBQc3CXMeta || {},
    alias: restoreXfmIBQc3CXMeta?.alias || [],
    redirect: restoreXfmIBQc3CXMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/auth/organisations/restore.vue").then(m => m.default || m)
  },
  {
    name: register_45completeKM8SCRxIXPMeta?.name ?? "auth-register-complete",
    path: register_45completeKM8SCRxIXPMeta?.path ?? "/auth/register-complete",
    meta: register_45completeKM8SCRxIXPMeta || {},
    alias: register_45completeKM8SCRxIXPMeta?.alias || [],
    redirect: register_45completeKM8SCRxIXPMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/auth/register-complete.vue").then(m => m.default || m)
  },
  {
    name: registerfZ778dyCrpMeta?.name ?? "auth-register",
    path: registerfZ778dyCrpMeta?.path ?? "/auth/register",
    meta: registerfZ778dyCrpMeta || {},
    alias: registerfZ778dyCrpMeta?.alias || [],
    redirect: registerfZ778dyCrpMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: _91token_93SH6mdB9s8HMeta?.name ?? "boards-token",
    path: _91token_93SH6mdB9s8HMeta?.path ?? "/boards/:token()",
    meta: _91token_93SH6mdB9s8HMeta || {},
    alias: _91token_93SH6mdB9s8HMeta?.alias || [],
    redirect: _91token_93SH6mdB9s8HMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/boards/[token].vue").then(m => m.default || m)
  },
  {
    name: indexAsfCYUl1F4Meta?.name ?? "buzzer",
    path: indexAsfCYUl1F4Meta?.path ?? "/buzzer",
    meta: indexAsfCYUl1F4Meta || {},
    alias: indexAsfCYUl1F4Meta?.alias || [],
    redirect: indexAsfCYUl1F4Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/buzzer/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_939GM5JGBxcDMeta?.name ?? "dashboards-id",
    path: _91id_939GM5JGBxcDMeta?.path ?? "/dashboards/:id()",
    meta: _91id_939GM5JGBxcDMeta || {},
    alias: _91id_939GM5JGBxcDMeta?.alias || [],
    redirect: _91id_939GM5JGBxcDMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/dashboards/[id].vue").then(m => m.default || m)
  },
  {
    name: index4hzKfZ90vlMeta?.name ?? "dashboards",
    path: index4hzKfZ90vlMeta?.path ?? "/dashboards",
    meta: index4hzKfZ90vlMeta || {},
    alias: index4hzKfZ90vlMeta?.alias || [],
    redirect: index4hzKfZ90vlMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/dashboards/index.vue").then(m => m.default || m)
  },
  {
    name: token1zHzxwuiOaMeta?.name ?? "dashboards-token",
    path: token1zHzxwuiOaMeta?.path ?? "/dashboards/token",
    children: [
  {
    name: _91token_93wlA4lszU2iMeta?.name ?? "dashboards-token-token",
    path: _91token_93wlA4lszU2iMeta?.path ?? ":token()",
    meta: _91token_93wlA4lszU2iMeta || {},
    alias: _91token_93wlA4lszU2iMeta?.alias || [],
    redirect: _91token_93wlA4lszU2iMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/dashboards/token/[token].vue").then(m => m.default || m)
  }
],
    meta: token1zHzxwuiOaMeta || {},
    alias: token1zHzxwuiOaMeta?.alias || [],
    redirect: token1zHzxwuiOaMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/dashboards/token.vue").then(m => m.default || m)
  },
  {
    name: demomkyUXo5AUOMeta?.name ?? "demo",
    path: demomkyUXo5AUOMeta?.path ?? "/demo",
    meta: demomkyUXo5AUOMeta || {},
    alias: demomkyUXo5AUOMeta?.alias || [],
    redirect: demomkyUXo5AUOMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/demo.vue").then(m => m.default || m)
  },
  {
    name: indexukJNmmzbU2Meta?.name ?? "events",
    path: indexukJNmmzbU2Meta?.path ?? "/events",
    meta: indexukJNmmzbU2Meta || {},
    alias: indexukJNmmzbU2Meta?.alias || [],
    redirect: indexukJNmmzbU2Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: impressumd3gnkHIKm1Meta?.name ?? "impressum",
    path: impressumd3gnkHIKm1Meta?.path ?? "/impressum",
    meta: impressumd3gnkHIKm1Meta || {},
    alias: impressumd3gnkHIKm1Meta?.alias || [],
    redirect: impressumd3gnkHIKm1Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: indexEFXtqqzcboMeta?.name ?? "index",
    path: indexEFXtqqzcboMeta?.path ?? "/",
    meta: indexEFXtqqzcboMeta || {},
    alias: indexEFXtqqzcboMeta?.alias || [],
    redirect: indexEFXtqqzcboMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_936M2nLAJ7UcMeta?.name ?? "leaderboards-id",
    path: _91id_936M2nLAJ7UcMeta?.path ?? "/leaderboards/:id()",
    meta: _91id_936M2nLAJ7UcMeta || {},
    alias: _91id_936M2nLAJ7UcMeta?.alias || [],
    redirect: _91id_936M2nLAJ7UcMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/leaderboards/[id].vue").then(m => m.default || m)
  },
  {
    name: indexuf4rJG7SNlMeta?.name ?? "leaderboards",
    path: indexuf4rJG7SNlMeta?.path ?? "/leaderboards",
    meta: indexuf4rJG7SNlMeta || {},
    alias: indexuf4rJG7SNlMeta?.alias || [],
    redirect: indexuf4rJG7SNlMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/leaderboards/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93GlhBOWfi1WMeta?.name ?? "organisation-deals-id",
    path: _91id_93GlhBOWfi1WMeta?.path ?? "/organisation/deals/:id()",
    meta: _91id_93GlhBOWfi1WMeta || {},
    alias: _91id_93GlhBOWfi1WMeta?.alias || [],
    redirect: _91id_93GlhBOWfi1WMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/organisation/deals/[id].vue").then(m => m.default || m)
  },
  {
    name: indexF8fFYHp47wMeta?.name ?? "organisation-deals",
    path: indexF8fFYHp47wMeta?.path ?? "/organisation/deals",
    meta: indexF8fFYHp47wMeta || {},
    alias: indexF8fFYHp47wMeta?.alias || [],
    redirect: indexF8fFYHp47wMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/organisation/deals/index.vue").then(m => m.default || m)
  },
  {
    name: HSLtoRGBiYLbg4ksFPMeta?.name ?? "organisation-devices-HSLtoRGB",
    path: HSLtoRGBiYLbg4ksFPMeta?.path ?? "/organisation/devices/HSLtoRGB",
    meta: HSLtoRGBiYLbg4ksFPMeta || {},
    alias: HSLtoRGBiYLbg4ksFPMeta?.alias || [],
    redirect: HSLtoRGBiYLbg4ksFPMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/organisation/devices/HSLtoRGB.js").then(m => m.default || m)
  },
  {
    name: RGBtoHSLkasdqhYxAgMeta?.name ?? "organisation-devices-RGBtoHSL",
    path: RGBtoHSLkasdqhYxAgMeta?.path ?? "/organisation/devices/RGBtoHSL",
    meta: RGBtoHSLkasdqhYxAgMeta || {},
    alias: RGBtoHSLkasdqhYxAgMeta?.alias || [],
    redirect: RGBtoHSLkasdqhYxAgMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/organisation/devices/RGBtoHSL.js").then(m => m.default || m)
  },
  {
    name: _91id_93uhDr9y2LRNMeta?.name ?? "organisation-devices-id",
    path: _91id_93uhDr9y2LRNMeta?.path ?? "/organisation/devices/:id()",
    meta: _91id_93uhDr9y2LRNMeta || {},
    alias: _91id_93uhDr9y2LRNMeta?.alias || [],
    redirect: _91id_93uhDr9y2LRNMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/organisation/devices/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93DffktESwatMeta?.name ?? "organisation-devices-devicegroup-id",
    path: _91id_93DffktESwatMeta?.path ?? "/organisation/devices/devicegroup/:id()",
    meta: _91id_93DffktESwatMeta || {},
    alias: _91id_93DffktESwatMeta?.alias || [],
    redirect: _91id_93DffktESwatMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/organisation/devices/devicegroup/[id].vue").then(m => m.default || m)
  },
  {
    name: indexiA5kDPDgfrMeta?.name ?? "organisation-devices",
    path: indexiA5kDPDgfrMeta?.path ?? "/organisation/devices",
    meta: indexiA5kDPDgfrMeta || {},
    alias: indexiA5kDPDgfrMeta?.alias || [],
    redirect: indexiA5kDPDgfrMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/organisation/devices/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_934PSruS8JbzMeta?.name ?? "organisation-eventTypes-id",
    path: _91id_934PSruS8JbzMeta?.path ?? "/organisation/eventTypes/:id()",
    meta: _91id_934PSruS8JbzMeta || {},
    alias: _91id_934PSruS8JbzMeta?.alias || [],
    redirect: _91id_934PSruS8JbzMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/organisation/eventTypes/[id].vue").then(m => m.default || m)
  },
  {
    name: indexQjG0ELd2T4Meta?.name ?? "organisation-eventTypes",
    path: indexQjG0ELd2T4Meta?.path ?? "/organisation/eventTypes",
    meta: indexQjG0ELd2T4Meta || {},
    alias: indexQjG0ELd2T4Meta?.alias || [],
    redirect: indexQjG0ELd2T4Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/organisation/eventTypes/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_932jyN5ebLyQMeta?.name ?? "organisation-goals-id",
    path: _91id_932jyN5ebLyQMeta?.path ?? "/organisation/goals/:id()",
    meta: _91id_932jyN5ebLyQMeta || {},
    alias: _91id_932jyN5ebLyQMeta?.alias || [],
    redirect: _91id_932jyN5ebLyQMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/organisation/goals/[id].vue").then(m => m.default || m)
  },
  {
    name: indexff6dHtWGTMMeta?.name ?? "organisation-goals",
    path: indexff6dHtWGTMMeta?.path ?? "/organisation/goals",
    meta: indexff6dHtWGTMMeta || {},
    alias: indexff6dHtWGTMMeta?.alias || [],
    redirect: indexff6dHtWGTMMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/organisation/goals/index.vue").then(m => m.default || m)
  },
  {
    name: indexrQsCRZWNkaMeta?.name ?? "organisation-settings",
    path: indexrQsCRZWNkaMeta?.path ?? "/organisation/settings",
    meta: indexrQsCRZWNkaMeta || {},
    alias: indexrQsCRZWNkaMeta?.alias || [],
    redirect: indexrQsCRZWNkaMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/organisation/settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexzG2tsIYIwBMeta?.name ?? "organisation-subscriptions",
    path: indexzG2tsIYIwBMeta?.path ?? "/organisation/subscriptions",
    meta: indexzG2tsIYIwBMeta || {},
    alias: indexzG2tsIYIwBMeta?.alias || [],
    redirect: indexzG2tsIYIwBMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/organisation/subscriptions/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93fDMG1ANLmzMeta?.name ?? "organisation-users-id",
    path: _91id_93fDMG1ANLmzMeta?.path ?? "/organisation/users/:id()",
    meta: _91id_93fDMG1ANLmzMeta || {},
    alias: _91id_93fDMG1ANLmzMeta?.alias || [],
    redirect: _91id_93fDMG1ANLmzMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/organisation/users/[id].vue").then(m => m.default || m)
  },
  {
    name: indexJDACXwihqpMeta?.name ?? "organisation-users",
    path: indexJDACXwihqpMeta?.path ?? "/organisation/users",
    meta: indexJDACXwihqpMeta || {},
    alias: indexJDACXwihqpMeta?.alias || [],
    redirect: indexJDACXwihqpMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/organisation/users/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93jnFOkkE5g6Meta?.name ?? "sounds-id",
    path: _91id_93jnFOkkE5g6Meta?.path ?? "/sounds/:id()",
    meta: _91id_93jnFOkkE5g6Meta || {},
    alias: _91id_93jnFOkkE5g6Meta?.alias || [],
    redirect: _91id_93jnFOkkE5g6Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/sounds/[id].vue").then(m => m.default || m)
  },
  {
    name: index1sqwfT3DrAMeta?.name ?? "sounds",
    path: index1sqwfT3DrAMeta?.path ?? "/sounds",
    children: [
  {
    name: entriesbQnGSywiCbMeta?.name ?? "sounds-index-entries",
    path: entriesbQnGSywiCbMeta?.path ?? "entries",
    meta: entriesbQnGSywiCbMeta || {},
    alias: entriesbQnGSywiCbMeta?.alias || [],
    redirect: entriesbQnGSywiCbMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/sounds/index/entries.vue").then(m => m.default || m)
  },
  {
    name: playlists1exrZCJAnHMeta?.name ?? "sounds-index-playlists",
    path: playlists1exrZCJAnHMeta?.path ?? "playlists",
    meta: playlists1exrZCJAnHMeta || {},
    alias: playlists1exrZCJAnHMeta?.alias || [],
    redirect: playlists1exrZCJAnHMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/sounds/index/playlists.vue").then(m => m.default || m)
  }
],
    meta: index1sqwfT3DrAMeta || {},
    alias: index1sqwfT3DrAMeta?.alias || [],
    redirect: index1sqwfT3DrAMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/sounds/index.vue").then(m => m.default || m)
  },
  {
    name: indexl9jZC9zEFQMeta?.name ?? "system-devices",
    path: indexl9jZC9zEFQMeta?.path ?? "/system/devices",
    meta: indexl9jZC9zEFQMeta || {},
    alias: indexl9jZC9zEFQMeta?.alias || [],
    redirect: indexl9jZC9zEFQMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/system/devices/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93U2QYTqiS1mMeta?.name ?? "system-firmware-id",
    path: _91id_93U2QYTqiS1mMeta?.path ?? "/system/firmware/:id()",
    meta: _91id_93U2QYTqiS1mMeta || {},
    alias: _91id_93U2QYTqiS1mMeta?.alias || [],
    redirect: _91id_93U2QYTqiS1mMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/system/firmware/[id].vue").then(m => m.default || m)
  },
  {
    name: indexfRvCdp04RyMeta?.name ?? "system-firmware",
    path: indexfRvCdp04RyMeta?.path ?? "/system/firmware",
    meta: indexfRvCdp04RyMeta || {},
    alias: indexfRvCdp04RyMeta?.alias || [],
    redirect: indexfRvCdp04RyMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/system/firmware/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93OhhFabqmvmMeta?.name ?? "system-hardware-id",
    path: _91id_93OhhFabqmvmMeta?.path ?? "/system/hardware/:id()",
    meta: _91id_93OhhFabqmvmMeta || {},
    alias: _91id_93OhhFabqmvmMeta?.alias || [],
    redirect: _91id_93OhhFabqmvmMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/system/hardware/[id].vue").then(m => m.default || m)
  },
  {
    name: indextTg3AVBznZMeta?.name ?? "system-hardware",
    path: indextTg3AVBznZMeta?.path ?? "/system/hardware",
    meta: indextTg3AVBznZMeta || {},
    alias: indextTg3AVBznZMeta?.alias || [],
    redirect: indextTg3AVBznZMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/system/hardware/index.vue").then(m => m.default || m)
  },
  {
    name: indexUSWYRsLn3HMeta?.name ?? "system-logs",
    path: indexUSWYRsLn3HMeta?.path ?? "/system/logs",
    meta: indexUSWYRsLn3HMeta || {},
    alias: indexUSWYRsLn3HMeta?.alias || [],
    redirect: indexUSWYRsLn3HMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/system/logs/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_936YPXOSOpI8Meta?.name ?? "system-notificationTemplates-id",
    path: _91id_936YPXOSOpI8Meta?.path ?? "/system/notificationTemplates/:id()",
    meta: _91id_936YPXOSOpI8Meta || {},
    alias: _91id_936YPXOSOpI8Meta?.alias || [],
    redirect: _91id_936YPXOSOpI8Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/system/notificationTemplates/[id].vue").then(m => m.default || m)
  },
  {
    name: indexmhhgomSBPqMeta?.name ?? "system-notificationTemplates",
    path: indexmhhgomSBPqMeta?.path ?? "/system/notificationTemplates",
    meta: indexmhhgomSBPqMeta || {},
    alias: indexmhhgomSBPqMeta?.alias || [],
    redirect: indexmhhgomSBPqMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/system/notificationTemplates/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93yr4owhcqpvMeta?.name ?? "system-organisations-id",
    path: _91id_93yr4owhcqpvMeta?.path ?? "/system/organisations/:id()",
    meta: _91id_93yr4owhcqpvMeta || {},
    alias: _91id_93yr4owhcqpvMeta?.alias || [],
    redirect: _91id_93yr4owhcqpvMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/system/organisations/[id].vue").then(m => m.default || m)
  },
  {
    name: indexcZSsKmuD8TMeta?.name ?? "system-organisations",
    path: indexcZSsKmuD8TMeta?.path ?? "/system/organisations",
    meta: indexcZSsKmuD8TMeta || {},
    alias: indexcZSsKmuD8TMeta?.alias || [],
    redirect: indexcZSsKmuD8TMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/system/organisations/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Wxt1GMkVtcMeta?.name ?? "system-plans-id",
    path: _91id_93Wxt1GMkVtcMeta?.path ?? "/system/plans/:id()",
    meta: _91id_93Wxt1GMkVtcMeta || {},
    alias: _91id_93Wxt1GMkVtcMeta?.alias || [],
    redirect: _91id_93Wxt1GMkVtcMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/system/plans/[id].vue").then(m => m.default || m)
  },
  {
    name: indexNyBnsVzypoMeta?.name ?? "system-plans",
    path: indexNyBnsVzypoMeta?.path ?? "/system/plans",
    meta: indexNyBnsVzypoMeta || {},
    alias: indexNyBnsVzypoMeta?.alias || [],
    redirect: indexNyBnsVzypoMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/system/plans/index.vue").then(m => m.default || m)
  },
  {
    name: releaselogwmz60BZHHkMeta?.name ?? "system-releaselog",
    path: releaselogwmz60BZHHkMeta?.path ?? "/system/releaselog",
    meta: releaselogwmz60BZHHkMeta || {},
    alias: releaselogwmz60BZHHkMeta?.alias || [],
    redirect: releaselogwmz60BZHHkMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/system/releaselog.vue").then(m => m.default || m)
  },
  {
    name: toolssqTwhD8gdoMeta?.name ?? "system-tools",
    path: toolssqTwhD8gdoMeta?.path ?? "/system/tools",
    meta: toolssqTwhD8gdoMeta || {},
    alias: toolssqTwhD8gdoMeta?.alias || [],
    redirect: toolssqTwhD8gdoMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/system/tools.vue").then(m => m.default || m)
  },
  {
    name: _91id_93iJs50yRMUJMeta?.name ?? "system-users-id",
    path: _91id_93iJs50yRMUJMeta?.path ?? "/system/users/:id()",
    meta: _91id_93iJs50yRMUJMeta || {},
    alias: _91id_93iJs50yRMUJMeta?.alias || [],
    redirect: _91id_93iJs50yRMUJMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/system/users/[id].vue").then(m => m.default || m)
  },
  {
    name: indexwKby0QEOYJMeta?.name ?? "system-users",
    path: indexwKby0QEOYJMeta?.path ?? "/system/users",
    meta: indexwKby0QEOYJMeta || {},
    alias: indexwKby0QEOYJMeta?.alias || [],
    redirect: indexwKby0QEOYJMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241127160105/client/pages/system/users/index.vue").then(m => m.default || m)
  }
]